import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

class XSignInRedirect extends LitElement {
  @property({ type: String, reflect: true }) redirectUrl = '';
  @property({ type: Boolean, reflect: true }) debugMode = false;
  @property({ type: Boolean }) isLoading = false;

  static styles = css`
    .x-button {
      width: 100%;
      background-color: #000;
      background-image: url('/images/x-logo.svg');
      background-position: 12px center;
      background-repeat: no-repeat;
      background-size: 17px 17px;
      border-radius: 5px;
      border: none;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      outline: none;
      text-align: center;
      text-decoration: none;
      text-indent: 30px;
      transition: background-color 0.3s;
      height: 40px;
      transition: background-color 0.3s, box-shadow 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
      white-space: nowrap;
    }
    .x-button:hover,
    .x-button:focus {
      background-color: #0f0f0f;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    .x-button:active {
      background-color: #000;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .spinner {
      width: 100%;
      height: 100%;
      background-image: url('/images/loading.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 auto;
    }
  `;

  render() {
    return html`
      <button class="x-button" @click="${this.redirectToXSignIn}">
        ${this.isLoading ? html`<div class="spinner"></div>` : 'Continue with X'}
      </button>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async redirectToXSignIn() {
    this.isLoading = true;
    this.requestUpdate();
    try {
      await Ayetu.auth.signIn('X');
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.requestUpdate();
    }
  }
}

customElements.define('x-signin', XSignInRedirect);
